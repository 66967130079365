.topheader {
  /* z-index: 999; */
  padding-top: 10px;
}
.user-profile-dropdown{
  display: flex;
  align-items: center;
  gap: 5px;
}
.colorChange .fixed-top {
  background: #131128;
  transition: 0.6s;
  padding-top: 0;
}
.navbar {
  transition: 0.6s;
}

.footer-social-media-links ol, ul {
  padding-left: 0 !important;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  gap: 76px;
  /* height: 100px; */
  justify-content: initial;
  
}

.App {
  background: #0B1124;
}

.display::after {
  display: none;
}
.hover:hover .dropdown-menu {
  display: block;
  transition: 0.6s;
  margin-top: 0;
}
.mobile-nav {
  /* background-color: #131128 !important; */
  z-index: 999;
}
.navbar-light .navbar-nav .nav-link {
  /* width: 115px; */
  /* color: #fff;
  font-size: 14px;
  line-height: 17.85px;
  padding: 10px 0px !important;
  font-family:"Audiowide" ;
  font-weight: 400; */
  color: #fff;
  font-family: "Bungee";
  font-size: 0.85rem !important;
  font-weight: 400;
  line-height: 17.85px;
  padding: 10px 0!important;
  letter-spacing: 0.08em;
  white-space: nowrap;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: cadetblue;
}
.header-icons {
  padding-top:12px;
}
.head-btn {
  background-color: #4a6bbc;
  border-radius: 26px;
  font-size: 14px;
  color: #fff;
  padding: 6px 20px;
  text-transform: uppercase;
  transition: 0.6s;
}
.card-display {
  /* display: none; */
  visibility: hidden;
}
.card-visible {
  visibility: visible;
}
.nav-drop {
  padding: 2.5rem 0rem 0rem 0rem;
}
.nav-menu {
  /* padding-left: 100px; */
  position: relative;
  align-items: center;
}

.navbar-nav {
  gap: 15px;
}
.header-right{
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.header-menu-list{
  padding-left: 30px;
}

.header-content{
  display:flex;
 flex-direction: row !important;
}

.navbar-collapse{
  justify-content: space-between;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  background: #3c2485;
}
ul.dropdown-menu li a {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Bungee';
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #4a6bbc;
  background-color: transparent;
}

.userprofile {
  justify-content: center;
  gap: 10px;
}

.dropdown-menu.notification-wrapper {
  background-color: rgb(75, 110, 188);
  list-style: none;
  margin: 0px;
  text-align: left;
  width: 320px 0px;
  padding: 0px;
  margin-top: 8px;
  right: -8px;
}

.notification-wrapper:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0d8";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  top: -20px;
  right: 10px;
  color: #4b6ebc;
  z-index: 999999;
  font-size: 26px;
}
.dropdown-menu.notification-wrapper ul {
  margin: 0;
  padding: 0;
  max-height: 440px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dropdown-menu.notification-wrapper ul::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  background-color: transparent;
}

.dropdown-menu.notification-wrapper ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px #28498f;
  box-shadow: inset 0 0 0px #28498f;
  border-radius: 3px;
}

.dropdown-menu.notification-wrapper ul::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px #28498f;
  box-shadow: inset 0 0 6px #28498f;
  background-color: #28498f;
}

.notification hr {
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: #ffff;
}

.logout {
  background-color: #3c2485;
  transition: none;
  margin-left: -20px !important;
}

.logout:hover {
  color: #4a6bbc;
}

.profile {
  background-color: #3c2485;
  transition: none;
}

.profile:hover {
  color: #4a6bbc;
}

.dropdown-menu.notification-wrapper[data-bs-popper] {
  left: -700%;
}
/* .collapse.navbar-collapse {
  flex-grow: inherit;
} */
.notifications {
  justify-content: flex-start;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: white;
  margin-left: 0px !important;
  /* border-bottom: 1px solid #fff; */
}

.notifications i {
  margin-right: 10px;
  font-size: 12px;
  padding-top: 5px;
}

ul.notification li.notifications a {
  font-size: 12.5px;
  color: #fff;
}

.notifications:hover {
  background-color: #2e52a1;
}

.notifications.no-hover:hover {
  background-color: #4a6bbc;
}

/* .userprofile {
  margin-left: 50px;
} */
/* 
  .userprofile li {
    margin-right: 20px;
  } */

.userprofile li:last-child {
  margin-left: 20px;
}

ul.dropdown-menu.userprofile-dd-menu.show {
  left: inherit;
}
ul.dropdown-menu.userprofile-dd-menu button.head-btn {
  font-size: 12px !important;
  font-family: 'Bungee';
}

/* footer start */

.footer-sec {
  /* background-image: url(../images/footer-background.png); */
  background-color: #0B1124;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 38px;
  background-position: center;
  background-size: cover;
}
.copylink p {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 16px;
  line-height: 24px;
}
hr.hr-line {
  color: #fff;
}
.foot-list ul.footer-menu {
  display: flex;
  justify-content: center;
  color: #fff;
  list-style: none;
  font-size: 14px;
  line-height: 17px;
  gap: 10px 60px;
}
.foot-list a.foot-link {
  color: #fff;
  padding: 5px;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Bungee';
}
.foot-list {
  padding-top: 30px;
  padding-bottom: 13px;
}
.desktop-nav {
  display: block;
  z-index: 9999999;
}
.mobile-nav {
  display: none;
}
.mobile-nav .navbar-light .navbar-toggler-icon {
  background-image: url(../images/menuicon.png);
}
.mobile-nav a.nav-link {
  color: #000;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

li {
  list-style: none;
  text-decoration: none;
}

.justify-content-end.flex-grow-1.pe-3.navbar-nav a {
  padding: 7px;
  text-decoration: none;
  color: #000;
  font-family: 'Bungee';
}
a.nav-link:hover {
  color: #6c8cdd !important;
}

.a {
  color: #000;
  text-decoration: none !important;
}
.copylink p {
  margin-bottom: 0;
}
ul.footer-menu {
  padding-left: 0;
}
.footer-sec hr.hr-line {
  margin-top: 0px;
  margin-bottom: 15px;
}
/* ul.footer-menu li {
  margin-left: 27px;
} */
.footer-menu li.nav-item:first-child {
  margin-left: 0;
}
a.foot-link:hover {
  color: #6c8cdd;
  transition: 0.6s;
}
a.foot-link {
  color: #6c8cdd;
  transition: 0.6s;
}

li.nav-item.active ul.dropdown-menu {
  display: block;
}
li.nav-item ul.dropdown-menu {
  display: none;
}
li.nav-item.hover.false:hover ul.dropdown-menu {
  display: none;
}
ul.navbar-nav li.nav-item.notActive:hover ul.dropdown-menu {
  display: block;
}

.logout-btn {
  margin-left: 0px !important;
}

.profile-btn,
.logout-btn {
  text-align: left;
  padding: 9px 10px !important;
}

.profile-btn:hover,
.logout-btn:hover {
  color: #0d6efd !important;
}
.offcanvas-end {
  width: 250px;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/* Social media links */
/* 
.footer-social-media-wrapper {
  width: 97%;
} */

.footer-social-media-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.footer-social-media-link a i {
  font-size: 1.5rem;
  color: #6c8cdd;
  transition: all 0.2s ease-in-out;
}

.footer-social-media-link a i:hover {
  color: #3d60b9;
}

.account-bal {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #1DCADC;
  margin-top: 3px;
}
.bonus-bal {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 31px;
  color: #1ddc3d;
  margin-top: 3px;
}
/* responsive */

@media only screen and (max-width: 1399.98px) {
  .desktop-nav .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
}
}

@media only screen and (max-width: 1300px) {
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    gap: 50px;
  }
}

@media only screen and (max-width: 1250px) {
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    gap: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .nav-menu {
    padding-left: 0px;
  }
  .no-nav{
    gap:  0px !important;
  }
  .navbar-nav {
    gap: 10px;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    padding: 0px !important;
  }
  .me-auto {
    margin: 0 auto;
  }
  form.d-flex {
    right: 0;
  }
  .head-btn {
    font-size: 13px;
    padding: 9px 11px;
  }
  .mobile-nav-actions {
    flex-direction: column;
  }

 /* ul:first-child{
  padding-left: 0px;
 } */
}
@media only screen and (max-width: 1270px) {
  .mobile-nav {
    display: block;
  }
  .mobile-nav .container-fluid{
    justify-content: space-between;
    gap: unset;
  }
  .desktop-nav {
    display: none !important;
  }
  /* .header-responsive {
    padding-right: 20% !important;
  } */
  .navbar-expand-lg .navbar-nav {
    margin-right: 1.5%;
  }
  .account-bal {
    margin-right: 1.5%;
  }
  .f-20 {
    font-size: 17px !important;
  }
  .nav-menu {
    padding-left: 0px;
  }

  
  ul.footer-menu li {
    margin-left: 0px;
  }
  .home-offcanvas-header,
  .home-offcanvas-body {
    background: #0B1124;
  }
  .justify-content-end.flex-grow-1.pe-3.navbar-nav a {
    color: #dee2e6;
  }
  .head-btn {
    background: #0b0b27;
  }
  .signIn-btn {
    padding: 10px;
    width: 100%;
    text-align: left;
    padding: 9px 10px !important;
  }

  .signIn-btn:hover {
    color: #0d6efd !important;
  }
  .dropdown-menu.notification-wrapper {
    right: -10px;
    top: 28px;
  }

  ul.notification li.notifications a {
    font-size: 11px;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    justify-content: space-between;
  }
  .page-bottom{
    position: absolute;
    bottom: 0px;
  }
  /* .offcanvas {
    visibility: hidden;
    background-color: #fff;
  } */
}
@media only screen and (max-width: 772px) {
  .nav-tabs:nth-child(2) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .nav-tabs:nth-child(2) {
    grid-template-columns: repeat(2, 1fr);
    padding: 22px;
    grid-gap: 20px;
  }
  /* .footer-social-media-links {
    padding-right: 25px;
  } */
  .footer-social-media-link a i {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 576px) {
.foot-list{
  padding-top: 0px !important;
}
}
.mobilelogo{
  height: 45px;
}